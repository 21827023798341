import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { UserContext } from "src/context/UserContext";

import { useAuth } from "src/hooks/api";

export default function RouteGuard({ children }) {
  const router = useRouter();
  const { isAuthenticated, isPending, isError } = useAuth();
  const { language } = useContext(UserContext);

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath, isAuthenticated, isPending, isError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, router, isPending]);

  const navigate = (path) => {
    if (path) {
      router.push(`/${path}`, `/${path}`, { locale: language });
    }
  };

  function authCheck(url, authenticated, pending, error) {
    // redirect to login page if accessing a private page and not logged in
    const publicPaths = [
      "/auth/login",
      "/auth/forgotPassword",
      "/auth/signup",
      "/",
      "/knowledgeBase",
      "/home/about",
      "/home/about-en",
      "/home/privacy",
      "/home/privacy-en",
    ];
    const path = url.split("?")[0];
    if (
      isAuthenticated === false &&
      isAuthenticated !== undefined &&
      isAuthenticated !== null &&
      error &&
      !pending &&
      !publicPaths.includes(path)
    ) {
      setAuthorized(false);
      navigate("auth/login");
    } else if (isAuthenticated !== undefined && isAuthenticated !== null) {
      setAuthorized(true);
    }
  }

  return authorized && children;
}
