import { ChakraProvider } from "@chakra-ui/react";
import { ApiProvider } from "src/hooks/api";
import { UserProvider } from "src/context/UserContext";
import { RouteGuard } from "src/components";
import { SpeedInsights } from "@vercel/speed-insights/react";
import theme from "src/theme";
import Fonts from "src/theme/fonts";
import "../styles/globals.css";

if (process.env.NODE_ENV === "development") {
  console.log("dev");
  //require("../mocks");
}

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;

function MyApp({ Component, pageProps }) {
  return (
    <ApiProvider
      config={{
        apiBaseUrl,
        clientId,
      }}
    >
      <UserProvider>
        <ChakraProvider theme={theme}>
          <Fonts />
          <RouteGuard>
            <Component {...pageProps} />
            <SpeedInsights />
          </RouteGuard>
        </ChakraProvider>
      </UserProvider>
    </ApiProvider>
  );
}

export default MyApp;
