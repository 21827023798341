import React from "react";
import NextLink from "next/link";
import { Flex, Text, HStack, Image, Box, Link } from "@chakra-ui/react";

const CourseCard = ({
  disabled,
  attributes,
  links,
  title,
  startCourse,
  course,
  status,
  icon,
  downloadIcon,
}) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      //bg="primary"
      bgImage="/bg.svg"
      bgRepeat="no-repeat"
      opacity={disabled ? "50%" : "100%"}
      cursor="pointer"
      onClick={(event) => {
        startCourse(course);
        event.target.blur();
      }}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <HStack spacing="25px">
        <Box
          p={3}
          opacity={` ${
            status?.status && status?.status === "PASSED" ? 0.5 : 1
          }`}
        >
          <Image
            src={icon ? "/modules/" + icon + ".png" : "modules/checkmark.png"}
            alt="module icon"
            objectFit="contain"
            w="35px"
          />
        </Box>
        <Text
          pt={1.5}
          pb={1.5}
          color="baseLight"
          fontWeight="normal"
          textTransform="uppercase"
        >
          {/* {attributes?.title} */}
          {title}
        </Text>
      </HStack>
      <Box p={3}>
        <Image
          src={
            downloadIcon
              ? "modules/" + downloadIcon + ".png"
              : "modules/play.png"
          }
          alt="Start module"
          objectFit="contain"
          w="35px"
        />
      </Box>
    </Flex>
  );
};

export default CourseCard;
